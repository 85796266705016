import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { SessionService } from "./session.service";
import { AuthService } from "./auth.service";

@Injectable()
export class ZmHttpInterceptor implements HttpInterceptor {

    constructor(private translate: TranslateService, private authService: AuthService) { }

    private addConnId(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({ setHeaders: { 'x-zm-session-id': SessionService.getSessionId() } });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addConnId(req)).pipe(catchError(error => {
            let errMsg = error.error;

            if (typeof errMsg == 'object' && errMsg.hasOwnProperty('zm-err')
                && errMsg.hasOwnProperty("message")) {
                let errorKey = 'error.server.' + errMsg['zm-err'];


                let emsg = this.translate.instant(errorKey);
                emsg = errorKey == emsg ? errMsg['message'] : emsg;

                if (errMsg['zm-err'] == 690 || errMsg['zm-err'] == 811) this.authService.sessionExpired(); // session expired
                if (errMsg['zm-err'] == 717) {
                    this.authService.lockAccount(true); // session expired
                    this.authService.setLockoutDuration(errMsg['message']); // session expired
                }
                else if (errMsg['zm-err'] == 701) {//special handling for already exist error mesg
                    let arr = errMsg['message'].match(/^'(.*)' .*/);
                    if (arr.length == 2) {
                        emsg = '\'' + arr[1] + '\' ' + emsg;

                    }
                } else if (errMsg['zm-err'] == 709) {
                    emsg = errMsg['message'];
                }
                return throwError(emsg);
            }

            // errMsg = 'Unable to make request to server. Please check your network connection.';
            // return throwError(errMsg);
            let emsg = this.translate.instant('error.server.000');
            
            return throwError(emsg);
        }));;
    }
}
