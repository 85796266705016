import { COMMA, SPACE, ENTER } from '@angular/cdk/keycodes';

export class Constants {
  public static TAGS_SEPRATOR_KEYCODE: number[] = [SPACE, COMMA, ENTER];

  public static LANGS: string[] = ['English', 'Japanese','Spanish'];
  public static LANGS_CODE_MAP = {
    English: 'en',
    Japanese: 'ja',
    Spanish: 'es'
  };
  public static LANGS_CODE: string[] = ['en', 'ja', 'es'];

  public static get WEEKDAYS(): string[] {
    return [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
  }
  public static get MONTHS(): string[] {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  //public static get MEDIA_FILTER_TYPES():string[] {return ["All","This Week","This Month","This Year"];}
  public static get MEDIA_FILTER_TYPES(): string[] {
    return [
      'ALL',
      'IMAGE',
      'VIDEO',
      'PDF',
      'HTML',
      'APPS',
      'WEB',
      'LAYOUT',
      'CANVA',
      'LIVE_TV',
      'STREAM',
      'YOUTUBE',
      'TICKER',
      'CANVAS',
      'RSS_TICKER',
    ];
  }

  public static LAYOUT_BG_COLOR_CONSTS: string[] = [
    '#c11efb',
    '#9442fd',
    '#7b77fd',
    '#a8baf9',
    '#cbc9fc',
  ];
  //    ['#FF8888','#CDB2A5', '#DBD199','#CDB2A5','#FFB86D','#DBD199'];

  public static THUMBNAIL_SMALL: string = '_zm_thumbnail_small.jpg';
  public static THUMBNAIL_FULL: string = '_zm_thumbnail_full.jpg';
  public static THUMBNAIL_FULL_END: string = '_zm_thumbnail_full_end.jpg';

  //public static get BASE_URL(): string  { return "http://localhost:9000";};
  public static get BASE_URL(): string {
    return '/api';
  }

  //display
  public static get DISPLAY_URL(): string {
    return Constants.BASE_URL + '/display';
  }

  public static get DISPLAY_SNAPSHOT_URL(): string {
    return Constants.BASE_URL + '/display/snap';
  }
  //display
  public static get PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/playlist';
  }

  //display
  public static get MEDIA_URL(): string {
    return Constants.BASE_URL + '/media';
  }

  //App
  public static get APP_URL(): string {
    return Constants.BASE_URL + '/app';
  }

  //display
  public static get LAYOUT_URL(): string {
    return Constants.BASE_URL + '/layout';
  }

  //display
  public static get SCHEDULE_URL(): string {
    return Constants.BASE_URL + '/schedule';
  }

  //dashboard
  public static get GET_DASHBOARD_STATS_URL(): string {
    return Constants.BASE_URL + '/dashboard_stats';
  }

  public static get SESSION_ACTIVE_URL(): string {
    return Constants.BASE_URL + '/get_active_session?startID=0&limit=100';
  }
  public static get SESSION_HISTORY_URL(): string {
    return Constants.BASE_URL + '/get_session_history?startID=0&limit=100';
  }
  public static get SESSION_DELETE_URL(): string {
    return Constants.BASE_URL + '/delete_session';
  }
  public static get CHANGE_PWD_URL(): string {
    return Constants.BASE_URL + '/change_password';
  }
  public static get GET_AUTHTOKEN_URL(): string {
    return Constants.BASE_URL + '/get_authkey';
  }
  public static get CREATE_AUTHTOKEN_URL(): string {
    return Constants.BASE_URL + '/create_authkey';
  }

  //settings
  public static get SETTINGS_URL(): string {
    return Constants.BASE_URL + '/settings';
  }

  public static get GET_TZONE_USER_PROF_URL(): string {
    return Constants.BASE_URL + '/get_userprofile?pname=timezone';
  }
  public static get GET_TZONE_DATA_URL(): string {
    return Constants.BASE_URL + '/assets/tz/timezone.json';
  }
  public static get DEL_TZONE_USER_PROF_URL(): string {
    return Constants.BASE_URL + '/delete_userprofile';
  }
  public static get CREATE_TZONE_USER_PROF_URL(): string {
    return Constants.BASE_URL + '/create_userprofile';
  }

  public static get GET_USER_PROF_URL(): string {
    return Constants.BASE_URL + '/get_userprofile';
  }
  public static get DEL_USER_PROF_URL(): string {
    return Constants.BASE_URL + '/delete_userprofile';
  }
  public static get CREATE_USER_PROF_URL(): string {
    return Constants.BASE_URL + '/create_userprofile';
  }

  public static get GET_DISP_PASSCODE_URL(): string {
    return Constants.BASE_URL + '/get_disp_passcode';
  }

  //Media
  public static get GET_MEDIA_LIST_URL(): string {
    return Constants.BASE_URL + '/get_medialibrary?startID=0';
  }
  public static get GET_MEDIA_LIST_BYTYPE(): string {
    return Constants.BASE_URL + '/get_medialibrary_bytype';
  }
  public static get GET_DELETABLE_MEDIA_LIST(): string {
    return Constants.BASE_URL + '/deletable_medias';
  }
  public static get GET_MEDIA_SYNC_STATUS(): string {
    return Constants.BASE_URL + '/media_sync_status';
  }
  public static get GET_RECENT_MEDIA_LIST_URL(): string {
    return Constants.BASE_URL + '/get_recent_medialibrary';
  }
  public static get DEL_MEDIA_URL(): string {
    return Constants.BASE_URL + '/delete_media';
  }
  public static get BULK_DEL_MEDIA_URL(): string {
    return Constants.BASE_URL + '/bulk_delete_media';
  }
  public static get UPLOAD_MEDIA_URL(): string {
    return Constants.BASE_URL + '/upload';
  }
  public static get IS_MEDIA_THUMBNAIL_AVAILABLE_URL(): string {
    return Constants.BASE_URL + '/is_media_thumbnail_available';
  }
  public static get GET_MEDIA_THUMBNAIL_URL(): string {
    return Constants.BASE_URL + '/media_thumb_image';
  }
  public static get GET_MEDIA_STREAM_URL(): string {
    return Constants.BASE_URL + '/stream_media';
  }

  //Pop Report
  public static get GET_POP_REPORT_TYPES_URL(): string {
    return Constants.BASE_URL + '/pop_report';
  }
  public static get GET_POP_REPORT_MLIST_URL(): string {
    return Constants.BASE_URL + '/pop_report';
  }
  public static get GET_POP_REPORT_FIELDLIST_URL(): string {
    return Constants.BASE_URL + '/pop_report';
  }
  public static get GET_POP_REPORT_URL(): string {
    return Constants.BASE_URL + '/pop_report';
  }
  public static get GET_POP_AGG_REPORT_URL(): string {
    return Constants.BASE_URL + '/pop_report';
  }
  public static get GET_POP_AGG_FIELD_REPORT_URL(): string {
    return Constants.BASE_URL + '/pop_report';
  }

  public static get GET_V2_POP_FINE_REPORT_URL(): string {
    return Constants.BASE_URL + '/popreport/fine';
  }
  public static get GET_V2_POP_AGG_REPORT_URL(): string {
    return Constants.BASE_URL + '/popreport/agg';
  }

  public static get GET_V2_POP_FIELDLIST_URL(): string {
    return Constants.BASE_URL + '/popreport/field';
  }

  public static get GET_V2_POP_STATS_URL(): string {
    return Constants.BASE_URL + '/popreport/stats';
  }

  // public static get GET_POP_REPORT_URL_V2() : string { return Constants.BASE_URL + "/get_pop_report_v2";}
  public static get GET_POP_EXPORT_REPORT_URL(): string {
    return Constants.BASE_URL + '/get_pop_export';
  }
  // public static get GET_POP_EXPORT_REPORT_URL_V2() : string { return Constants.BASE_URL + "/get_pop_export_v2";}

  //CTA Report
  public static get GET_CTA_REPORT_MLIST_URL(): string {
    return Constants.BASE_URL + '/get_cta_medialist';
  }
  public static get GET_CTA_REPORT_URL(): string {
    return Constants.BASE_URL + '/get_cta_report';
  }
  public static get GET_CTA_EXPORT_REPORT_URL(): string {
    return Constants.BASE_URL + '/get_cta_export';
  }

  //Audit Report
  public static get GET_AUDIT_REPORT_URL(): string {
    return Constants.BASE_URL + '/audit_report';
  }

  //Event Report
  public static get GET_EVENTLOG_REPORT_URL(): string {
    return Constants.BASE_URL + '/event_report';
  }

  //feed review
  public static get GET_ADD_APP_URL(): string {
    return Constants.BASE_URL + '/add_app';
  }
  public static get GET_REFRESH_TOKEN_URL(): string {
    return Constants.BASE_URL + '/refresh_token';
  }
  public static get GET_FEEDS_URL(): string {
    return Constants.BASE_URL + '/feeds';
  }
  public static get GET_ADD_FEED_URL(): string {
    return Constants.BASE_URL + '/add_feed';
  }
  public static get GET_ADD_HASHTAG_URL(): string {
    return Constants.BASE_URL + '/add_hashtag';
  }
  public static get GET_DEL_HASHTAG_URL(): string {
    return Constants.BASE_URL + '/remove_hashtag';
  }
  public static get GET_FB_BASE_URL(): string {
    return 'https://graph.facebook.com/v2.9';
  }
  public static get GET_FEED_REVIEW_POST_URL(): string {
    return Constants.BASE_URL + '/review_posts';
  }
  public static get GET_FEED_REJECT_URL(): string {
    return Constants.BASE_URL + '/reject_post';
  }
  public static get GET_FEED_APPROVE_URL(): string {
    return Constants.BASE_URL + '/approve_post';
  }
  public static get GET_FB_FEED_TOKEN(): string {
    return Constants.BASE_URL + '/reviewapp_feed_token';
  }
  public static get GET_FB_NEW_USER_TOKEN(): string {
    return Constants.GET_FB_BASE_URL + '/oauth/access_token';
  }

  //broadcast
  public static get GET_CHANNEL_LIST_URL(): string {
    return Constants.BASE_URL + '/get_dug?startID=0';
  }
  public static get GET_DISPLAY_FOR_CHANNEL_URL(): string {
    return Constants.BASE_URL + '/get_displayunit_for_dug';
  }
  public static get GET_PLAYLIST_FOR_CHANNEL_URL(): string {
    return Constants.BASE_URL + '/get_dug_playlist';
  }
  public static get GET_PLAYLIST_FOR_MEDIA_URL(): string {
    return Constants.BASE_URL + '/get_playlist_by_media';
  }
  public static get GET_FREE_DISPLAY_LIST_URL(): string {
    return Constants.BASE_URL + '/get_free_displayunit';
  }
  public static get GET_MEDIA_FOR_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/get_media_playlist';
  }
  public static get GET_EDIT_PLAYLIST_MEDIA_URL(): string {
    return Constants.BASE_URL + '/edit_playlist_media';
  }
  public static get GET_CHANNEL_PLAYLIST_DELETE_URL(): string {
    return Constants.BASE_URL + '/delete_channel_playlist';
  }
  public static get GET_CREATE_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/create_dug_playlist_post';
  }
  public static get GET_CREATE_NEW_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/create_playlist_post';
  }
  public static get GET_EDIT_CHANNEL_URL(): string {
    return Constants.BASE_URL + '/edit_channel_post';
  }
  public static get GET_CREATE_CHANNEL_URL(): string {
    return Constants.BASE_URL + '/create_channel_post';
  }
  public static get GET_DELETE_CHANNEL_URL(): string {
    return Constants.BASE_URL + '/delete_channel';
  }
  public static get GET_SCHEDULE_DATA_URL(): string {
    return Constants.BASE_URL + '/get_schedule_playlist';
  }
  public static get GET_ADD_SCHEDULE_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/add_schedule_playlist';
  }
  public static get GET_DEL_SCHEDULE_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/delete_schedule_playlist';
  }
  public static get GET_DELETE_CHANNEL_DISPLAY_URL(): string {
    return Constants.BASE_URL + '/delete_channel_display';
  }
  public static get GET_REPLACE_CHANNEL_DISPLAY_URL(): string {
    return Constants.BASE_URL + '/replace_channel_display';
  }
  public static get GET_DELETE_PLAYLIST_MEDIA_URL(): string {
    return Constants.BASE_URL + '/delete_playlist_media';
  }
  public static get GET_DELETE_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/delete_playlist';
  }
  public static get GET_CHANNEL_BY_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/get_dug_by_playlist';
  }
  public static get GET_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/get_playlists?startID=0';
  }
  public static get GET_EDIT_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/edit_playlist_v2';
  }
  public static get GET_ADD_PLAYLIST_URL(): string {
    return Constants.BASE_URL + '/add_playlist_to_dug';
  }
  public static get GET_CREATE_DISPLAY(): string {
    return Constants.BASE_URL + '/config_new_display';
  }
  public static get GET_ADD_AND_ASSOCIATE_DISPLAY(): string {
    return Constants.BASE_URL + '/add_and_associate_display';
  }
  public static get GET_PREVIEW_URL(): string {
    return '/preview';
  }
  public static get GET_UPDATE_MEDIA_EXPIRY(): string {
    return Constants.BASE_URL + '/update_media_expiry';
  }
  public static get GET_UPDATE_MEDIA_CALLTOACTION(): string {
    return Constants.BASE_URL + '/update_media_cfa';
  }
  public static get GET_REPLACE_MEDIA(): string {
    return Constants.BASE_URL + '/replace_media';
  }
  public static get GET_PLAYLIST_BY_ID(): string {
    return Constants.BASE_URL + '/get_playlist_by_id';
  }
  public static get GET_CHANNEL_BY_ID(): string {
    return Constants.BASE_URL + '/get_dug_by_id';
  }
  public static get GET_CREATE_LIVETV_MEDIA(): string {
    return Constants.BASE_URL + '/create_media_livetv';
  }
  public static get GET_CREATE_STREAM_MEDIA(): string {
    return Constants.BASE_URL + '/create_media_stream';
  }
  public static get GET_CREATE_YOUTUBE_MEDIA(): string {
    return Constants.BASE_URL + '/create_media_youtube';
  }
  public static get GET_CREATE_TICKER_MEDIA(): string {
    return Constants.BASE_URL + '/create_media_ticker';
  }
  public static get GET_UPDATE_TICKER_MEDIA(): string {
    return Constants.BASE_URL + '/update_media_ticker';
  }
  public static get GET_UPDATE_CANVA_MEDIA(): string {
    return Constants.BASE_URL + '/update_media_canva';
  }
  public static get GET_CREATE_TEMPLATE_JSON(): string {
    return Constants.BASE_URL + '/create_canvas_json';
  }
  public static get GET_GET_TEMPLATE_JSON(): string {
    return Constants.BASE_URL + '/get_canvas_json';
  }
  public static get GET_UPLOAD_TEMPLATE_IMAGE(): string {
    return Constants.BASE_URL + '/upload_canvas_image';
  }
  public static get GET_GET_TEMPLATE_IMAGES(): string {
    return Constants.BASE_URL + '/get_canvas_images';
  }
  public static get GET_GET_TEMPLATE_SVGS(): string {
    return Constants.BASE_URL + '/get_canvas_svgs';
  }
  public static get GET_STREAM_TEMPLATE_IMAGE(): string {
    return Constants.BASE_URL + '/stream_canvas_image';
  }
  public static get GET_STREAM_TEMPLATE_SVG(): string {
    return Constants.BASE_URL + '/stream_canvas_svg';
  }
  public static get GET_DELETE_TEMPLATE_IMAGE(): string {
    return Constants.BASE_URL + '/delete_canvas_image';
  }
  public static get GET_DELETE_TEMPLATE_SVG(): string {
    return Constants.BASE_URL + '/delete_canvas_svg';
  }

  //authservice
  public static get GET_LOGIN_URL(): string {
    return Constants.BASE_URL + '/login';
  }
  public static get GET_LOGOUT_URL(): string {
    return Constants.BASE_URL + '/logout';
  }
  public static get GET_LOGIN_USER_INFO_URL(): string {
    return Constants.BASE_URL + '/get_loggedin_user_info';
  }
  public static get GET_IS_USER_LOGGEDIN_URL(): string {
    return Constants.BASE_URL + '/is_user_loggedin';
  }

  public static get GET_OAUTH_AUTHORIZE_URL(): string {
    return Constants.BASE_URL + '/oauth/authorize';
  }

  public static get GET_OAUTH_LOGIN_URL(): string {
    return Constants.BASE_URL + '/oauth/login';
  }

  public static get GET_OAUTH_LOGOUT_URL(): string {
    return Constants.BASE_URL + '/oauth/logout';
  }

  public static get GET_DOMAIN_PROFILE_URL(): string {
    return  'assets/extra/profile/profile.json';
  }

  //agentsettings
  public static get GET_AGENT_LOGO_URL(): string {
    return Constants.BASE_URL + '/agent_logo';
  }
  public static get GET_AGENT_LOGO_POS_URL(): string {
    return Constants.BASE_URL + '/agent_logo_pos';
  }
  public static get GET_GET_AGENT_LOGO_POS_URL(): string {
    return Constants.BASE_URL + '/get_agent_logo_pos';
  }
  public static get GET_CLEAR_AGENT_LOGO_URL(): string {
    return Constants.BASE_URL + '/clear_agent_logo';
  }
  public static get GET_AGENT_TRANSITION_URL(): string {
    return Constants.BASE_URL + '/agent_transition';
  }

  //campain-layout
  public static get GET_CREATE_LAYOUT(): string {
    return Constants.BASE_URL + '/create_layout';
  }
  public static get GET_MODIFY_LAYOUT(): string {
    return Constants.BASE_URL + '/modify_layout';
  }
  public static get GET_ADD_LAYOUT_MEDIA(): string {
    return Constants.BASE_URL + '/add_layout_medias';
  }
  public static get GET_DEL_LAYOUT_MEDIA(): string {
    return Constants.BASE_URL + '/delete_layout_medias';
  }
  public static get GET_UPDATE_LM_DURATION(): string {
    return Constants.BASE_URL + '/update_lm_duration';
  }
  public static get GET_UPDATE_LM_PLAYORDER(): string {
    return Constants.BASE_URL + '/update_lm_play_order';
  }
  public static get GET_GET_LAYOUTS(): string {
    return Constants.BASE_URL + '/layouts';
  }
  public static get GET_GET_LAYOUT_METAS(): string {
    return Constants.BASE_URL + '/layout_metas';
  }
  public static get GET_GET_LAYOUT_META_BY_ID(): string {
    return Constants.BASE_URL + '/layout_meta_by_id';
  }
  public static get GET_GET_LAYOUT_METAS_FOR_MEDIA(): string {
    return Constants.BASE_URL + '/layout_metas_by_media';
  }
  public static get GET_LAYOUT_UPLOAD_THUMBNAIL(): string {
    return Constants.BASE_URL + '/upload_layout_thumbnail';
  }

  //user account
  public static get GET_CREATE_USER(): string {
    return Constants.BASE_URL + '/create_user';
  }
  public static get GET_DELETE_USER(): string {
    return Constants.BASE_URL + '/delete_user';
  }
  public static get GET_USER_LIST(): string {
    return Constants.BASE_URL + '/get_userlist';
  }
  public static get GET_DISPLAY_FOR_USER(): string {
    return Constants.BASE_URL + '/get_display_for_user';
  }
  public static get GET_SET_DISPLAY_PERMISSION_FOR_USER(): string {
    return Constants.BASE_URL + '/set_display_permission';
  }
  public static get GET_CLEAR_DISPLAY_PERMISSION_FOR_USER(): string {
    return Constants.BASE_URL + '/clear_display_permission';
  }

  //API Token
  public static get GEN_API_TOKEN(): string {
    return Constants.BASE_URL + '/gen_api_key';
  }
  public static get GET_API_TOKEN(): string {
    return Constants.BASE_URL + '/get_api_key';
  }
  public static get DEL_API_TOKEN(): string {
    return Constants.BASE_URL + '/del_api_key';
  }

  //SETTINGS POWER SCHEDULE
  public static get GET_POWER_SCHEDULE(): string {
    return Constants.BASE_URL + '/settings/display/power_schedule';
  }

  //kiosk management
  public static get GET_CREATE_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/create_enterprise';
  }
  public static get GET_DELETE_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/delete_enterprise';
  }
  public static get GET_UPDATE_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/update_enterprise';
  }
  public static get GET_GET_ALL_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/get_all_enterprise';
  }
  public static get GET_ADD_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/add_enterprise';
  }
  public static get GET_GET_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/get_enterprise';
  }
  public static get GET_CREATE_ENTROLLMENT_TOKEN(): string {
    return Constants.BASE_URL + '/emm/create_entroll_token';
  }
  public static get GET_KIOSK_DEVICE_LIST(): string {
    return Constants.BASE_URL + '/emm/device_list';
  }
  public static get GET_KIOSK_DEVICE_REBOOT(): string {
    return Constants.BASE_URL + '/emm/reboot_device';
  }
  public static get GET_KIOSK_DEVICE_LOCK(): string {
    return Constants.BASE_URL + '/emm/lock_device';
  }
  public static get GET_KIOSK_DEVICE_DELETE(): string {
    return Constants.BASE_URL + '/emm/delete_device';
  }
  public static get GET_CREATE_POLICY(): string {
    return Constants.BASE_URL + '/emm/create_policy';
  }
  public static get GET_DELETE_POLICY(): string {
    return Constants.BASE_URL + '/emm/delete_policy';
  }
  public static get GET_UPDATE_POLICY(): string {
    return Constants.BASE_URL + '/emm/update_policy';
  }
  public static get GET_GET_POLICY_ENTERPRISE(): string {
    return Constants.BASE_URL + '/emm/get_policy_enterprise';
  }

  public static get GET_TEMPLATE_BASEURL(): string {
    return 'assets/templates';
  }
}
