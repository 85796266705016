/**
 * Created by giridhar on 20/9/17.
 */

import {
  CommandCallHolder,
  CommandParser,
  NotificationClient,
} from './notification_client';
import { Command } from './command';
import { ServiceType } from './service_type';
import { After, ZMWCallback } from './zm_notification';
import { SocialCommandParser } from './pub_sub_command_parser';
import {
  ResourceCommand,
  PubSubCommand,
  SubCommand,
  UnSubCommand,
} from './pub_sub_commands';
import { Utility } from 'src/app/util/utility';
import { Result } from './result';

export class PubSubNotificationClient extends NotificationClient {
  
  private static readonly _TAG: string = 'app.notification.social_client.SocialNotificationClient';

  private readonly subMap: Map<string, number>;
  private connListener: (connected: boolean) => void;

  public constructor(wsUrl: string) {
    super(wsUrl);
    this.subMap = new Map<string, number>();
    this.connListener = null;
  }

  public setConnListener(reConnCB: (status: boolean) => void) {
    this.connListener = reConnCB;
  }

  subscribe(serviceType: ServiceType, callback: ZMWCallback, service: string, after: After): void {
    let cmd: Command = new SubCommand(NotificationClient.getNextId(), serviceType, service, after);

    //register in sub map
    let subString: string = NotificationClient.uniqueSubService(serviceType, service);

    if (this.subMap.has(subString)) throw new Error('Already subscribed for service ' + subString);

    let holder: CommandCallHolder = this.createCommandCallHolder(cmd, callback);
    this.subMap.set(subString, holder.cmd.getId());
    this.writeCommand(holder);
  }

  unSubscribe(serviceType: ServiceType, callback: ZMWCallback, service: string): void {
    let cmd: Command = new UnSubCommand(NotificationClient.getNextId(), serviceType, service);
    let holder: CommandCallHolder = this.createCommandCallHolder(cmd, callback);
    this.writeCommand(holder);
  }

  resource(serviceType: ServiceType, callback: ZMWCallback, resource: string): void {
    let cmd: Command = new ResourceCommand(
      NotificationClient.getNextId(),
      serviceType,
      resource
    );
    let holder: CommandCallHolder = this.createCommandCallHolder(cmd, callback);
    this.writeCommand(holder);
  }

  private clearSub(cmd: PubSubCommand): void {
    let subString: string = NotificationClient.uniqueSubService(cmd.getServiceType(), cmd.getService());
    let subId: number | undefined = this.subMap.get(subString);
    if (Utility.isNON(subId)) {
      console.error('no matching id subscription for service ' + subString);
      return;
    }
    this.cmdCallbackMap.delete(subId);
    this.subMap.delete(subString);
  }

  onResultCmd(cmd: Command, hasFailureResp: boolean) {
    //if UNSUBSCRIBE or SUBSCRIBE but failure, then clear sub
    if (
      cmd.getType() === PubSubCommand.UNSUBSCRIBE ||
      (cmd.getType() === PubSubCommand.SUBSCRIBE && hasFailureResp)
    ) {
      this.clearSub(<PubSubCommand>cmd);
    }
  }

  chkAndProcessRetry(holder: CommandCallHolder, result: Result) {
    if (holder.cmd.getType() === PubSubCommand.SUBSCRIBE &&
            result.getResp() === 'PLEASE TRY AFTER SOME TIME') {
                let sc: SubCommand = <SubCommand> holder.cmd;
                setTimeout(
                    () => {
                        try {
                            this.subscribe(sc.getServiceType(), holder.callback, sc.getService(), sc.getAfter());
                        } catch (err) {
                          console.error('Exception while retrying ' + sc.getId, err)
                        }
                    },
                    20000); // retry after 20 sec
        }
  }

  createCMDParser(): CommandParser {
    return new SocialCommandParser();
  }

  onDisconnect(): void {
    if(this.connListener) this.connListener(false);
  }

  onConnect(): void {
    if(this.connListener) this.connListener(true);
  }

  stop(): void {
    super.stop();
    this.subMap.clear();
  }
}
