/**
 * Created by giridhar on 2/11/16.
 */

export class ServiceType {

    private readonly name:string;

    private constructor(name:string) {
        this.name = name;
    }

    public getName():string {
        return this.name;
    }

    public static readonly C_SYNC = new ServiceType('C_SYNC');
    public static readonly DAO_CHANGE = new ServiceType('DAO_CHANGE')
}
