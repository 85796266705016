/**
 * Created by giridhar on 3/11/16.
 */

import { Utility } from 'src/app/util/utility';

export class WebSocketClient {
  private static CONN_ID: number = 0;

  private readonly url: string;
  private ws: WebSocket | null;

  private isOpen: boolean;
  private isClosed: boolean;
  private connId: number;
  //private closeFun!: (ev: CloseEvent) => any | null;
  private closeFun!: any;

  public constructor(url: string) {
    this.url = url;
    this.isOpen = false;
    this.isClosed = false;
    this.ws = null;
    this.connId = 0;
  }

  public connect(listener: Listener): void {
    if (this.ws !== null) {
      throw new Error('Previous connection not closed properly ' + this.ws.OPEN);
    }

    this.ws = new WebSocket(this.url);
    this.connId = ++WebSocketClient.CONN_ID;

    this.closeFun = (ev: CloseEvent) => {
      this.isClosed = true;
      this.isOpen = false;
      this.ws = null;
      this.closeFun = null;
      listener.onDisconnect(this.connId, ev);
    };

    this.ws.addEventListener('close', this.closeFun);

    this.ws.addEventListener('error', (ev: any) =>
      listener.onError(this.connId, ev.error)
    );
    this.ws.addEventListener('open', () => {
      this.isOpen = true;
      this.isClosed = false;
      listener.onConnect(this.url, this.connId);
    });

    this.ws.addEventListener('message', (ev: MessageEvent) =>
      listener.onMessage(this.connId, ev.data)
    );
  }

  public getConnectionId(): number {
    return this.connId;
  }

  public send(data: string): void {
    if (this.isClosed) {
      throw new Error('Connection closed');
    }
    if (!this.isOpen) {
      throw new Error('Not open');
    }
    this.ws?.send(data);
  }

  public disconnect(): void {
    if (!Utility.isNON(this.ws)) {
      this.ws?.removeEventListener('close', this.closeFun);
      this.closeFun = null;
      this.ws?.close();
      this.ws = null; //clear ws
    }
    this.isClosed = true;
    this.isOpen = false;
  }
}

export interface Listener {
  onConnect(url: string, connId: number): void;
  onMessage(connId: number, msg: string): void;
  onDisconnect(connId: number, ev: CloseEvent): void;
  onError(connId: number, err: Error): void;
}
