import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CanvaService {
  private canvaAPI: any;
  private initialized: boolean = false;

  constructor() {
    this.initCanvaAPI();
  }

  initCanvaAPI() {
    let self = this;
    if (this.initialized) return;

    // @ts-ignore
    if (window.CanvaButton) {
      // @ts-ignore
      window.CanvaButton.initialize({
        apiKey: 'DBwhjZ87UC8XpO_qhxWQXq0w',
      }).then(function (api) {
        self.canvaAPI = api;
        self.initialized = true;
      });
    }
  }

  getCanvaAPI() {
    return this.canvaAPI;
  }
}
