import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../constants/constants';
import { Result } from '../core/notification/result';
import { UserInfo } from '../model/common-types';
import { AppService } from './app.service';
import { SessionService } from './session.service';
import { PubSubNotificationClient } from '../core/notification/pub_sub_client';
import { ServiceType } from '../core/notification/service_type';
import { ZMNotification } from '../core/notification/zm_notification';

export const JSON_HEADER = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const BASE_SERVICE_REF_MAP: Map<string, BaseService[]> = new Map();
const DATA_HEADER = 'x-zm-data';
const HEADER_VAL_LEN = 4000; // IIS max value is 5000 so, limiting to 4000

export abstract class BaseService {

  private static subscribed: boolean = false;
  private static firstEVENT = true;

  protected static psn: PubSubNotificationClient = null;

  constructor(protected readonly http: HttpClient, basePaths: string[]) {
    for (let i = 0; i < basePaths.length; i++) {
      let bp = basePaths[i];
      let bsArr: BaseService[] = BASE_SERVICE_REF_MAP.get(bp);
      bsArr = bsArr || [];
      bsArr.push(this);
      BASE_SERVICE_REF_MAP.set(bp, bsArr);
    }

    this.init();
  }

  private init(): void {
    if (BaseService.subscribed || !this.isLoggedIn()) return;
    this.subEvent();
  }

  protected getAppService(): AppService {
    let aSer = <AppService[]>BASE_SERVICE_REF_MAP.get('/app');
    return aSer ? aSer[0] : null;
  }

  protected _createDualObservable<T, U>(pri: BehaviorSubject<T>, dep: Observable<U>) {
    return new Observable<T>((subscriber: Subscriber<T>) => {
      const depSub = dep.subscribe();
      const subscription = pri.subscribe(subscriber);

      return () => {
        subscription.unsubscribe();
        depSub.unsubscribe();
      }
    });
  }

  protected login(loginForm: any): Promise<UserInfo> {
    this.freeRes(); // clear any old data
    /*
         Gen new session-id; must be called before calling actual http request, else
         wrong session-id will be supplied
        */
    SessionService.genNewSessoion();

    return this.http.post<UserInfo>(Constants.GET_LOGIN_URL, loginForm, JSON_HEADER)
      .pipe(tap(uInfo => {
        this.onLogin();
        return uInfo
      })).toPromise();
  }


  protected oauthLogin(data:any):Promise<UserInfo>{
    this.freeRes(); // clear any old data
    /*
         Gen new session-id; must be called before calling actual http request, else
         wrong session-id will be supplied
        */
    SessionService.genNewSessoion();
    return this.http.post<UserInfo>(Constants.GET_OAUTH_LOGIN_URL, data, JSON_HEADER)
    .pipe(
      tap(uInfo => {
        this.onLogin();
        return uInfo
      })).toPromise();
  }

  protected isLoggedIn(): boolean {
    return localStorage.getItem('logged_in') == 'true';
  }

  protected _logout(): Promise<void> {
    return this.http.post<void>(Constants.GET_LOGOUT_URL, JSON_HEADER)
      .pipe(tap(_ => this.processLogout()))
      .toPromise();
  }

  protected processSessionExpired(): void {
    this.processLogout();
  }

  protected getAsHeaderData(data: string): { [header: string]: string } {
    data = encodeURIComponent(data);
    let headers: { [header: string]: string } = {};
    let hId = 0;
    for (let i = 0; i < data.length; ) {
      let end = Math.min(data.length - i, HEADER_VAL_LEN);
      headers[DATA_HEADER + '-' + hId] = data.substr(i, end);
      hId++;
      i = i + end;
    }
    return headers;
  }

  private onLogin(): void {
    this.subEvent();
  }

  private processLogout(): void {
    localStorage.removeItem('logged_in');
    this.freeRes();
  }

  private freeRes(): void {
    BaseService.subscribed = false;

    BASE_SERVICE_REF_MAP.forEach(vArr => vArr.forEach(v => v.freeResource()));
    // Stop any old request. This is very important as users will logout and login without refreshing the browser
    //So, there should not be any resource leak
    BaseService.psn?.stop();
    BaseService.psn = null;
  }

  private getEventURL(): string {
    let host = window.location.host;
    let schema = window.location.protocol === 'http:' ? 'ws:' : 'wss:';

    return schema + '//' + host + '/event?session=' + SessionService.getSessionId()
  }

  private subEvent(): void {
    if (BaseService.subscribed) return;

    BaseService.psn = new PubSubNotificationClient(this.getEventURL());

    try {
      BaseService.firstEVENT = true;

      BaseService.psn
        .setConnListener(connected => {
          if (BaseService.firstEVENT && connected) BaseService.firstEVENT = false;
          else BASE_SERVICE_REF_MAP.forEach(vArr => vArr.forEach(v => v.onCUDEventSubStatus(connected)));
        });

      BaseService.psn.subscribe(ServiceType.DAO_CHANGE, {
        onReply(resp: Result): void {
          try {
            if (!resp.isSuccess())
              return console.error('Got failure resposne ' + resp.getResp());
            if (resp.getResp() === 'OK') return;

            let data = JSON.parse(resp.getResp());
            let verb: Verb = data['verb'];
            let path: string = data['path'];
            let pathArr: string[] = data['path'].split('/');
            let bsArr: BaseService[] = null;

            do {
              bsArr = BASE_SERVICE_REF_MAP.get(pathArr.join('/'));
              if (bsArr) break;

              pathArr.pop();
            } while (pathArr.length > 0);

            if (bsArr == null) return console.log(`Missing service for ${path}`);
            bsArr.forEach(bs => bs.onCUDEvent(verb, path));
          } catch (err) {
            console.error(err);
          }
        },
        /*
       for now, service for this serviceType can be any string; will be
       used at the client layer and not at server layer
      */
      },
        'DAO',
        ZMNotification.DUMMY_AFTER);
    } catch (err) {
      console.error(err);
    }
    BaseService.subscribed = true;
  }

  protected abstract onCUDEvent(verb: Verb, path: string): void;
  protected abstract freeResource(): void;
  protected abstract onCUDEventSubStatus(sub: boolean): void;
}

export type Verb = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

export interface Change {
  verb: Verb;
  ids: number[];
}
