/**
 * Created by giridhar on 2/11/16.
 */

import { ServiceType } from "./service_type";

export abstract class Command {

    private readonly id: number;
    private readonly command: string;
    private readonly type: CommandType;
    private readonly serviceType: ServiceType;

    constructor(id: number, type: CommandType, serviceType: ServiceType, ...args: any[]) {
        this.id = id;
        this.command = this.createCommand(id, type, serviceType, ...args);
        this.type = type;
        this.serviceType = serviceType;
    }

    abstract createCommand(nextId: number, cmdType: CommandType, serviceType: ServiceType, ...args: any[]): string;

    public getId(): number {
        return this.id;
    }

    public getCommand(): string {
        return this.command;
    }

    public getServiceType(): ServiceType {
        return this.serviceType;
    }

    public getType(): CommandType {
        return this.type;
    }

    public toString(): string {
        return this.getCommand();
    }
}

export class CommandType {

    private readonly multiResponse: boolean;
    private readonly name:string;

    constructor(name:string, multiResponse: boolean) {
        this.name = name;
        this.multiResponse = multiResponse;
    }

    isMultiResponse(): boolean {
        return this.multiResponse;
    }

    public getName():string {
        return this.name;
    }
}