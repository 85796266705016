/**
 * Created by giridhar on 27/9/16.
 */

export class ResolvablePromise<R,E> {
    private promise:Promise<R>;
    private res:(R) => void;
    private rej:(E) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.res = resolve;
            this.rej = reject;
        })
    }

    resolve(resp:R):void {
        this.res(resp);
    }

    reject(err:E):void {
        this.rej(err);
    }

    getPromise() {
        return this.promise;
    }
}