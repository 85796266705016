import { RouteAction } from '../model/common-types';

export class Utility {
  static getBrowserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static removeObjFromArray(list: any[], obj: any) {
    let index = list.indexOf(obj);
    if (index != -1) Utility.removeObjAt(list, index);
  }

  static removeObjAt(list: any[], index: number) {
    list.splice(index, 1);
  }

  static has(id: number, inArr: number[]): boolean {
    for (let i = 0; i < inArr.length; i++) {
      if (inArr[i] == id) return true;
    }
    return false;
  }

  static deepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
  }

  static fmtDuration(dur: number) {
    return dur < 10 ? '0' + dur : dur;
  }

  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static binarySearch<T, V>(
    arr: Array<T>,
    el: V,
    compare_fn: (a: V, b: T) => number
  ) {
    let m = 0;
    let n = arr.length - 1;
    while (m <= n) {
      let k = (n + m) >> 1;
      let cmp = compare_fn(el, arr[k]);
      if (cmp > 0) {
        m = k + 1;
      } else if (cmp < 0) {
        n = k - 1;
      } else {
        return k;
      }
    }
    return ~m;
  }

  static bytesToMetric(bytes: number) {
    if (Math.floor(bytes / 1000) == 0) {
      return bytes + ' B';
    } else if (Math.floor(bytes / 1000000) == 0) {
      return Math.floor(bytes / 1000) + ' KB';
    } else if (Math.floor(bytes / 1000000000) == 0) {
      return Math.floor(bytes / 1000000) + ' MB';
    } else {
      return Math.floor(bytes / 1000000000) + ' GB';
    }
  }

  static dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  public static isNON(value: any): boolean {
    return value === undefined || value === null || value === '';
  }

  public static isEmpty(object: any): boolean {
    for (var i in object) {
      return false;
    }
    return true;
  }

  public static trim(str: string): string {
    if (!str) return str;
    return str.replace(/^[ ]+|[ ]+$/g, '');
  }

  public static getRouteUrl(
    prefix: string,
    id: number | string,
    action: RouteAction
  ) {
    prefix = prefix[prefix.length - 1] == '/' ? prefix : prefix + '/';
    return prefix + id + '/' + action;
  }

  public static getValue<T>(value: T, defaultValue: T): T {
    return Utility.isNON(value) ? defaultValue : value;
  }

  public static uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public static versionCompare(ver1: string, ver2: string): number {
    let ver1Arry = ver1.split('.');
    let ver2Arry = ver2.split('.');

    let i = 0;
    while (
      i < ver1Arry.length &&
      i < ver2Arry.length &&
      ver1Arry[i] === ver2Arry[i]
    ) {
      i++;
    }
    if (i < ver1Arry.length && i < ver2Arry.length) {
      let diff = parseInt(ver1Arry[i]) - parseInt(ver2Arry[i]);
      return Math.sign(diff);
    } else {
      return Math.sign(ver1Arry.length - ver2Arry.length);
    }
  }

  public static keyValueEntriesToObject(kvArray: any): any {
    return kvArray.reduce((o, kvPairObj) => {
      o[kvPairObj.key] = kvPairObj.value;
      return o;
    }, {});
  }

  static getRelativeTimeSpanString(
    previous: number,
    lastSeenLabel: any
  ): string {
    let current = new Date().getTime();
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;

    let elapsed = current - previous;
    elapsed = elapsed < 0 ? 0 : elapsed;

    if (elapsed < msPerMinute) {
      return Math.floor(elapsed / 1000) + ' ' + lastSeenLabel.seconds;
    } else if (elapsed < msPerHour) {
      return Math.floor(elapsed / msPerMinute) + ' ' + lastSeenLabel.minutes;
    } else if (elapsed < msPerDay) {
      return Math.floor(elapsed / msPerHour) + ' ' + lastSeenLabel.hours;
    } else if (elapsed < msPerMonth) {
      return Math.floor(elapsed / msPerDay) + ' ' + lastSeenLabel.days;
    } else if (elapsed < msPerYear) {
      return Math.floor(elapsed / msPerMonth) + ' ' + lastSeenLabel.months;
    } else {
      //return Math.floor(elapsed / msPerYear) + " years ago";
      return ' 1 ' + lastSeenLabel.months;
    }
  }

  public static isIPaddressValid(ipaddress: string) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  public static getCordAsInt(latitude: number, longitude: number): number {
    let lat = latitude * 1000000;
    let lng = longitude * 1000000;
    return Math.abs((lat << 16) | lng);
  }

  public static toHHMMSS_short(sec) {
    let sec_num = parseInt(sec, 10); // don't forget the second parm
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let Ohours = hours + '';
    let Ominutes = minutes + '';
    let Oseconds = seconds + '';
    if (hours < 10) {
      Ohours = '0' + hours;
    }
    if (minutes < 10) {
      Ominutes = '0' + minutes;
    }
    if (seconds < 10) {
      Oseconds = '0' + seconds;
    }

    return (hours == 0) ? Ominutes + ':' + Oseconds:  Ohours + ':' + Ominutes + ':' + Oseconds;
    /* let time = Ohours + ':' + Ominutes + ':' + Oseconds;
    return time;
    */
  }

  public static toHHMMSSFromEpoch(time: number) {
    //from epoch time in millis
    let d = new Date(time);
    return (
      this.fmtDuration(d.getHours()) +
      ':' +
      this.fmtDuration(d.getMinutes()) +
      ':' +
      this.fmtDuration(d.getSeconds())
    );
  }

  public static toYYYYMMDDFromEpoch(time: number) {
    //from epoch time in millis
    let d = new Date(time);
    return (
      d.getFullYear() +
      '-' +
      this.fmtDuration(d.getMonth()+1) +
      '-' +
      this.fmtDuration(d.getDate()) 
    );
  }

  public static isValidHTTPURL(url: string): boolean {
    if (!url) return false;

    try {
      let protocol = new URL(url).protocol;
      return protocol == 'http:' || protocol == 'https:';
    } catch (err) {
      return false;
    }
  }



  public static findPasswordStrength(pwd): number {
    if(pwd == undefined || pwd == null ) return 31;

    let strength = 0;
    //console.log(generateRandomPassword());
    if (!pwd.match(/.{8,}/)) {
      strength |= PasswordStregnth.MIN_8_CHAR;
    }

    if (!pwd.match(/[a-z]+/)) {
      strength |= PasswordStregnth.MIN_1_SMALL;
    }

    if (!pwd.match(/[A-Z]+/)) {
      strength |= PasswordStregnth.MIN_1_UPPER;
    }

    if (!pwd.match(/[0-9]+/)) {
      strength |= PasswordStregnth.MIN_1_NUMERICAL;
    }

    if (!pwd.match(/[!@#$%^&*()\-_+]+/)) {
      strength |= PasswordStregnth.MIN_1_SPECIAL;
    }

    return strength;
  }

  public static getPwdStrengthLabel(pwd): string {
    if(pwd == undefined || pwd == null ) return ''
    
    if (! pwd.match(/.{8,}/)) {
        return 'password_strength_8character';
    }

    if (!pwd.match(/[a-z]+/)) {
        return 'password_strength_1smallcase';
    }

    if (!pwd.match(/[A-Z]+/)) {
      return 'password_strength_1uppercase';
    }

    if (!pwd.match(/[0-9]+/)) {
      return 'password_strength_1number';
    }

    if (!pwd.match(/[!@#$%^&*()\-_+]+/)) {
      return 'password_strength_1special';
    }
    return '';
  }
  
  public static zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }
  
}


export enum PasswordStregnth {
  STRONG = 0,
  MIN_8_CHAR = 1,
  MIN_1_SMALL = 2,
  MIN_1_UPPER = 4,
  MIN_1_NUMERICAL = 8,
  MIN_1_SPECIAL = 16
}
