
export const ACLDATA = {
  dashboard: ['ADMIN', 'BROADCAST_MANAGER', 'CMS_VIEWER'],

  displays: ['ADMIN', 'DISPLAY_MANAGER', 'BROADCAST_MANAGER', 'CMS_VIEWER'],

  media: ['ADMIN', 'BROADCAST_MANAGER', 'CONTENT_MANAGER', 'CMS_VIEWER'],

  playlists: ['ADMIN', 'BROADCAST_MANAGER', 'CMS_VIEWER'],

  schedules: ['ADMIN', 'BROADCAST_MANAGER', 'CMS_VIEWER'],

  apps: ['ADMIN', 'BROADCAST_MANAGER', 'CONTENT_MANAGER', 'CMS_VIEWER'],

  layout: ['ADMIN', 'BROADCAST_MANAGER', 'CONTENT_MANAGER', 'CMS_VIEWER'],

  report: ['ADMIN', 'BROADCAST_MANAGER', 'CMS_VIEWER', 'POP_MANAGER'],

  canva: ['ADMIN', 'BROADCAST_MANAGER', 'CONTENT_MANAGER', 'CMS_VIEWER'],

  'settings/logo': ['ADMIN'],
  'settings/transition': ['ADMIN'],
  'settings/fields': ['ADMIN'],
  'settings/custom_variable': ['ADMIN'],
  'settings/power_schedule': ['ADMIN','CMS_VIEWER'],
  'settings/passcode': ['ADMIN', 'DISPLAY_MANAGER'],
  'settings/api_token': ['ADMIN'],
  'settings/password': [
    'ADMIN',
    'DISPLAY_MANAGER',
    'BROADCAST_MANAGER',
    'CONTENT_MANAGER',
    'CMS_VIEWER',
    'POP_MANAGER',
  ],
  'settings/mfa': [
    'ADMIN',
    'DISPLAY_MANAGER',
    'BROADCAST_MANAGER',
    'CONTENT_MANAGER',
    'CMS_VIEWER',
    'POP_MANAGER',
  ],
  'settings/preference': ['ADMIN', 'CMS_VIEWER' ],
  'settings/active_session': ['ADMIN'],
  'settings/session_history': ['ADMIN'],
  'settings/user_accounts': ['ADMIN'],
  'settings/ip_filter': ['ADMIN'],
  'settings/audit': ['ADMIN','CMS_VIEWER'],
  'settings/eventlog': ['ADMIN','CMS_VIEWER'],
};

