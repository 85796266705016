import { Utility } from "../util/utility";

export class SessionService {

  private static sessionId: string = Utility.uuidv4();

  static genNewSessoion(): void {
    SessionService.sessionId = Utility.uuidv4();
  }

  static getSessionId(): string {
    return SessionService.sessionId;
  }
}
