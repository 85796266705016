import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { Constants } from './constants/constants';
import { AuthService } from './services/auth.service';
import { CanvaService } from './services/canva.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'cms-webclient-v2';

  public constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private canvaService: CanvaService,
    private swUpdate: SwUpdate
  ) {}

  ngOnInit() {
    this.setLanguagePreferencByDomain();
    this.getLanguagePreference();
    if ( location && location.hostname !== 'apps.tads-signage.com') {
      this.loadCanvaAPIScript();
    }
    this.loadDomainProfile();

  }

  ngAfterViewInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.swUpdate.activateUpdate().then(() => {
          console.log('Update is available. Reloading 2...');
          window.location.reload();
        });
      });

      interval(5 * 60 * 1000).subscribe(() =>
        this.swUpdate.checkForUpdate().then(null)
      );
    }
  }

  loadCanvaAPIScript() {
    var script = document.createElement('script');
    script.src = 'https://sdk.canva.com/v2/beta/api.js';
    script.onload = () => this.canvaService.initCanvaAPI();
    document.body.appendChild(script);
  }

  setLanguagePreferencByDomain() {
    this.translate.addLangs(Constants.LANGS_CODE);

    /*
    if (location && location.hostname === 'cms.digiads.com.vn') {
      this.translate.setDefaultLang('vi');
      this.translate.use('vi');
    }
    */
    
    if (location && location.hostname === 'cms.nimbus.es') {
      this.translate.setDefaultLang('es');
      this.translate.use('es');
    }
    else if (location && location.hostname === 'cloud.yamato-signage.com') {
      this.translate.setDefaultLang('ja');
      this.translate.use('ja');
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
  }

  loadDomainProfile(){
    this.authService.getDomainProfile().then(_ =>{}).catch(console.log);
  }
  getLanguagePreference() {
    this.authService
      .getUserInfo()
      .then((res) => {
        let code: string = Constants.LANGS_CODE_MAP[res.language];
        this.translate.use(code);
      })
      .catch(err => console.error(err));
  }
}
